var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._g(_vm._b({tag:"component",staticClass:"button relative appearance-none inline-flex items-center justify-center cursor-pointer focus:outline-none rounded-md",class:[
    {'opacity-75 pointer-events-none': _vm.loading},

    {'pl-6 h-12 hover:no-underline': !_vm.plain},
    {'pr-6': !_vm.plain && !_vm.$slots.appendIcon},
    {'pr-4': !_vm.plain && _vm.$slots.appendIcon},

    {'text-white bg-black border-black hover:bg-gray-800 active:bg-gray-600 shadow-sm active:shadow-inner': _vm.primary && !_vm.destructive && !_vm.plain},
    {'bg-white border border-gray-300 shadow-sm text-gray-700 hover:bg-gray-50 hover:text-gray-900 active:shadow-inner': !_vm.primary && !_vm.destructive && !_vm.plain},

    {'shadow-inner': !_vm.plain && _vm.active},

    {'bg-opacity-25 pointer-events-none': _vm.disabled},

    {'text-white bg-red-600 border-red-600 hover:bg-red-700 shadow-sm': _vm.destructive && !_vm.plain},
    {'text-red-600 hover:text-red-500 hover:underline': _vm.destructive && _vm.plain},

    {'text-pacific-600 hover:text-pacific-500 hover:underline': _vm.primary && !_vm.destructive && _vm.plain},
    {'text-gray-700 hover:underline': !_vm.primary && !_vm.destructive && _vm.plain},

    {'text-pacific-500 hover:underline hover:text-pacific-400': _vm.link}
  ],attrs:{"type":_vm.type,"to":_vm.to,"href":_vm.href,"target":_vm.target,"disabled":_vm.loading || _vm.disabled}},'component',_vm.$attrs,false),_vm.$listeners),[(!_vm.plain && _vm.loading)?[_c('div',{staticClass:"absolute inset-0 flex items-center justify-center"},[_c('svg',{staticClass:"animate-spin w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z","clip-rule":"evenodd"}})])])]:_vm._e(),_c('div',{staticClass:"flex items-center",class:{'opacity-0': !_vm.plain && _vm.loading, 'opacity-75': _vm.plain && _vm.loading}},[(_vm.$slots.prependIcon)?_c('div',{staticClass:"w-5 mr-1"},[_vm._t("prependIcon")],2):_vm._e(),_c('div',{staticClass:"text-base text-center",class:{'leading-loose': !_vm.plain, 'font-medium': !_vm.link}},[_vm._t("default")],2),(_vm.$slots.appendIcon)?_c('div',{staticClass:"ml-2 -mr-2"},[_vm._t("appendIcon")],2):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'

// For icons
const requireComponents = require.context('@/components/ui/icons', true, /\.vue$/)
requireComponents.keys().forEach(componentPath => {
	const componentConfig = requireComponents(componentPath)
	let componentName: any = null
	if (componentConfig.default) {
		componentName = componentConfig.default.extendOptions ? componentConfig.default.extendOptions.name : componentConfig.default.name
	} else {
		componentName = componentConfig.extendOptions ? componentConfig.extendOptions.name : componentConfig.name
	}
	Vue.component(componentName, componentConfig.default || componentConfig)
})


import Button from '@/components/ui/Button.vue'
import ButtonsGroup from '@/components/ui/ButtonsGroup.vue'
import Form from '@/components/ui/Form.vue'
import FormField from '@/components/ui/FormField.vue'
import Pagination from '@/components/ui/Pagination.vue'
import Input from '@/components/ui/Input.vue'
import Card from '@/components/ui/card/Card.vue'
import CardSection from '@/components/ui/card/CardSection.vue'
import Section from '@/components/ui/Section.vue'
import Header from '@/components/ui/Header.vue'
import Badge from '@/components/ui/Badge.vue'
import Banner from '@/components/ui/Banner.vue'
import Dialog from '@/components/ui/Dialog.vue'
import Tab from '@/components/ui/tab/Tab.vue'
import TabPane from '@/components/ui/tab/TabPane.vue'
import Table from '@/components/ui/table/Table.vue'
import TableColumn from '@/components/ui/table/TableColumn.vue'
import Select from '@/components/ui/select/Select.vue'
import Loading from '@/components/ui/Loading.vue'
import Checkbox from '@/components/ui/Checkbox.vue'
import Switch from '@/components/ui/Switch.vue'
import ChoiceSelect from '@/components/ui/ChoiceSelect.vue'
import Tag from '@/components/ui/Tag.vue'
import Icon from '@/components/ui/Icon.vue'
import Filter from '@/components/ui/Filter.vue'

Vue.component('w-button', Button)
Vue.component('w-buttons-group', ButtonsGroup)
Vue.component('w-form', Form)
Vue.component('w-form-field', FormField)
Vue.component('w-pagination', Pagination)
Vue.component('w-input', Input)
Vue.component('w-card', Card)
Vue.component('w-card-section', CardSection)
Vue.component('w-section', Section)
Vue.component('w-header', Header)
Vue.component('w-badge', Badge)
Vue.component('w-banner', Banner)
Vue.component('w-dialog', Dialog)
Vue.component('w-tab', Tab)
Vue.component('w-tab-pane', TabPane)
Vue.component('w-table', Table)
Vue.component('w-table-column', TableColumn)
Vue.component('w-select', Select)
Vue.component('w-loading', Loading)
Vue.component('w-checkbox', Checkbox)
Vue.component('w-switch', Switch)
Vue.component('w-choice-select', ChoiceSelect)
Vue.component('w-tag', Tag)
Vue.component('w-icon', Icon)
Vue.component('w-filter', Filter)






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: 'Card'
})
export default class Card extends Vue {
  @Prop() public title?: string;
  @Prop(Boolean) public noPadding: Boolean;
}

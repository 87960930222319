















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectComponent extends Vue {
  @Prop() public value?: string;
  @Prop(Boolean) public disabled: Boolean;

  changeValue(ev: any) {
    if(!this.disabled) {
      this.$emit('input', ev.target.value)
    }
  }
}

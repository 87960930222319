import Vue from 'vue';
import Utils from '@/plugins/utils'

Vue.filter('decamelize', function(value) {
  return Utils.decamelize(value)
})
Vue.filter('capitalize', function (value) {
  return Utils.capitalize(value)
})
Vue.filter('lowerize', function (value) {
  return Utils.lowerize(value)
})
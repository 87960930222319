export default class User {

  public id: string = '';
  public email: string = '';

  constructor(data: { id: string, email: string }) {
    if(data) {
      this.id = data.id;
      this.email = data.email;
    }
  }
}
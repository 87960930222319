import axios from '@/plugins/axios';

export default {
  namespaced: true,
  state: {
    account: null,
    signature: null
  },
  actions: {
    async setSignature ({commit}: any, params: JSON): Promise<any> {
      commit('SET_SIGNATURE', params)
      return Promise.resolve(params)
    },
    async install ({commit, rootState}: any, shopUrl: string): Promise<any> {
      try {
        // rootState.shopify.signature.shop
        const response = await axios.post(`/providers/shopify/install`, { shopUrl })
        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async connect ({commit, dispatch, rootState}: any): Promise<any> {
      try {
        const response = await axios.post(`/providers/shopify/account/connect`, {
          type: 'shopify-tmall'
        }, { params: rootState.shopify.signature })

        commit('SET_ACCOUNT', response.data)
        axios.defaults.headers.common['x-account-id'] = response.data.id

        if(response.data.consumerTmall) {
          await dispatch('tmallProducts/fetchCounts', null, {root: true})
        }

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async disconnect ({commit, dispatch}: any ): Promise<any> {
      try {
        const response = await axios.post(`/providers/shopify/account/disconnect`)
        commit('SET_ACCOUNT', response.data)
        await dispatch('users/logout', null, { root: true})
        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async login ({commit, dispatch, rootState}: any): Promise<any> {
      try {
        const response = await axios.post(`/providers/shopify/login`, {
          type: 'shopify-tmall'
        }, { params: rootState.shopify.signature })

        if(response && response.data) {
          commit('SET_ACCOUNT', response.data.account)
          axios.defaults.headers.common['x-account-id'] = response.data.account.id
          
          commit('users/SET_USER', response.data.account.user, { root:true })
          await dispatch('users/persistToken', response.data.accessToken, { root:true })

          if(response.data.account.consumerTmall) {
            await dispatch('tmallProducts/fetchCounts', null, {root: true})
          }
        }
        
        return Promise.resolve(response.data)

      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchAccount ({commit, rootState}: any): Promise<any> {
      try {
        let suffix = rootState.context.consumer == 'tmall' ? '/shopify-tmall' : ''
        const response = await axios.get(`/providers/shopify/account${suffix}`)
        if(response.data) {
          commit('SET_ACCOUNT', response.data)
          axios.defaults.headers.common['x-account-id'] = response.data.id
          return Promise.resolve(response.data)
        } else {
          return Promise.resolve(null)
        }

      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  mutations: {
    SET_ACCOUNT (state: any, data: any) {
      state.account = data
    },
    SET_SIGNATURE (state: any, data: any) {
      state.signature = data
    }
  },
  getters: {
    getAccount(state: any) {
      return state.account
    },
    getSignature(state: any) {
      return state.signature
    }
  }
}
import { Commit, Dispatch } from 'vuex';
import axios from '@/plugins/axios';
import { AxiosResponse } from 'axios';
import { ITmallCredentials } from '@/types/interfaces';
import TmallCredentials from '@/models/TmallCredentials';

export default {
  namespaced: true,
  state: {
    credentials: null
  },
  actions: {
    async updateCredentials ({commit}: { commit: Commit }, { credentials }: { credentials: ITmallCredentials}) : Promise<any> {
      try {
        const response = await axios.put(`/consumers/tmall/credentials`, {
          clientId: credentials.clientId,
          clientSecret: credentials.clientSecret
        })
        return Promise.resolve(response.data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchCredentials ({commit}: { commit: Commit }) : Promise<any> {
      try {
        const response = await axios.get(`/consumers/tmall/credentials`)
        commit('SET_CREDENTIALS', response.data)
        return Promise.resolve(response.data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async login ({commit}: { commit: Commit }) : Promise<any> {
      try {
        const response = await axios.get(`/consumers/tmall/oauth/login`)
        return Promise.resolve(response.data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async deleteAccount({commit}: { commit: Commit }) : Promise<any> {
      try {
        const response = await axios.delete(`/consumers/tmall/account`)
        commit('shopify/SET_ACCOUNT', response.data, { root: true });
        return Promise.resolve(response.data)
      } catch (err) {
        return Promise.reject(err)
      }
    }
  },
  mutations: {
    SET_CREDENTIALS (state: any, credentials: TmallCredentials) {
      state.credentials = credentials || new TmallCredentials()
    }
  },
  getters: {
    getCredentials(state: any) {
      return state.credentials
    }
  }
}
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import ShopifyProduct from '@/models/ShopifyProduct';
import axios from '@/plugins/axios';
import { IPagination } from '@/types/interfaces';

@Module({namespaced: true})
export default class ShopifyProductsModule extends VuexModule {
  count:number = 0
  items:ShopifyProduct[] = []

  @Action//({ commit: 'setItems' })
  async fetchAll({page, pageSize}: IPagination): Promise<{items: ShopifyProduct[], count: number}> {

    let skip = (page - 1) * pageSize
    try {
      const response = await axios.get(`/providers/shopify/products/list?skip=${skip}&limit=${pageSize}&sort=createdAt`)
      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    } 
  }

  @Action//({ commit: 'setItems' })
  async searchAll({pagination, q, isMatched}: { pagination: IPagination, q: string, isMatched: Boolean}): Promise<{items: ShopifyProduct[], count: number}> {
    let skip = (pagination.page - 1) * pagination.pageSize
    try {
      const response = await axios.get(`/providers/shopify/products/search?skip=${skip}&limit=${pagination.pageSize}&isMatched=${isMatched}&sort=createdAt&q=${q}`)
      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Mutation
  setItems(payload: {items: ShopifyProduct[], count: number}) {
    this.items = payload.items
    this.count = payload.count
  }

  get getProducts(): {items: ShopifyProduct[], count: number} {
    return {
      items: this.items, count: this.count
    }
  }
}
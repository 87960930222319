























import { Component, Prop, Vue } from "vue-property-decorator";
import FormElement from '@/components/ui/FormElement.vue';

@Component
export default class Checkbox extends FormElement {

  @Prop({default: 'text'}) public type?: string;
  @Prop() public value?: string;
  @Prop() public data?: string;
  @Prop() public label?: string;
  @Prop() public help?: string;
  @Prop(Boolean) public disabled: Boolean;
  @Prop(Boolean) public readOnly: Boolean;

  selectItem() {
    if(!this.disabled) {
      let newValue = !this.value
      this.$emit('input', newValue)
      this.$emit('change', newValue)
    }
  }

  get selectedValue() {
    return this.value
  }
}

export default class TmallCredentials {

  public id: string = '';
  public clientId: string = '';
  public clientSecret: string = '';

  constructor(data?: { clientId: string, clientSecret: string }) {
    if(data) {
      this.clientId = data.clientId;
      this.clientSecret = data.clientSecret;
    }
  }
}












import { ValidationObserver } from 'vee-validate';
import { Component, Inject, Prop, Ref, Vue } from "vue-property-decorator";

@Component({ 
  name: 'Form',
  components: { ValidationObserver },
  
})
export default class Form extends Vue {
  @Ref()
  readonly observer!: InstanceType<typeof ValidationObserver>
  
  async validate() {
    const result = await this.observer.validate()
    return Promise.resolve(result)
  }
  async reset() {
    await this.observer.reset()
  }
  async submit () {
    const isValid = await this.observer.validate();
    if (!isValid) {
    } else {
      this.$emit('onSubmit')
    }
  }
}

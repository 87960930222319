<script>
export default {
  name: 'w-table-cell',
  functional: true,
  props: ['column', 'item', 'rowIndex'],
  render (h, { props, data }) {

    const dotGet = (obj, path) => {
      return path.split('.').reduce((o, i) => o[i], obj)
    }
    if (props.column.field) {
      let value = dotGet(props.item, props.column.field)
      if (typeof value !== 'string') {
        value = JSON.stringify(value)
      }

      if (props.column.scopedSlots && typeof props.column.scopedSlots.default === 'function') {
        return h('td', data, props.column.scopedSlots.default({ value, item: props.item, rowIndex: props.rowIndex, column: props.column }))
      }
      return h('td', data, value)
    }

    if (props.column.scopedSlots && typeof props.column.scopedSlots.default === 'function') {
      return h('td', data, props.column.scopedSlots.default(props))
    }

    return h('td', data, props.column.children)
  }
}
</script>
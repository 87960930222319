<template>
  <div>
    <div class="border-b" :class="{'px-2': !stickToLeft}">
      <div class="flex justify-between w-full">
        <div 
          class="-mb-px px-4 font-medium text-gray-500 inline-block cursor-pointer hover:text-indigo-700"
          :class="{'first:pl-0': stickToLeft}"
          v-for="(button, index) in buttons" :key="index">
          <component 
            v-tooltip="button.tooltip"
            :is="button.to && !button.disabled ? 'router-link' : 'div'" 
            :to="button.to" 
            @click="toggle(button)"
            class="flex items-center border-b-2 h-14 border-transparent"
            :active-class="!button.exact ? 'border-indigo-500 text-indigo-700' : ''"
            exact-active-class="border-indigo-500 text-indigo-700"
            :class="{
              'border-pacific-400 text-gray-800': !button.disabled && sharedState.activeItem == button.name,
              'text-gray-300': button.disabled,
              'text-gray-500 hover:text-gray-800': !button.disabled
            }">
            <w-icon v-if="button.icon" :pixels="12" :file="button.icon" type="regular" class="mr-2" />
            {{ button.title || button.name }}
          </component>
        </div>
        <div class="flex flex-1 items-center justify-end"><slot name="info"></slot></div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    stickToLeft: Boolean
  },
  data() {
    return {
      buttons:[],
      sharedState: {
        activeItem: null
      }
    }
  },
  watch: {
    value(newValue) {
      this.sharedState.activeItem = this.value
    }
  },
  provide() {
    return {
      buttons: this.buttons,
      menuState: this.sharedState
    }
  },
  created() {
    if(this.value) {
      this.sharedState.activeItem = this.value
    }
  },
  mounted() {
    if(this.buttons && this.buttons.length > 0) {
      if(!this.sharedState.activeItem) {
        this.sharedState.activeItem = !this.buttons[0].to ? this.buttons[0].name : null
      }
    }
  },
  methods: {
    toggle(button) {
      this.sharedState.activeItem = button.name
      this.$emit('tab-click', button)
      this.$emit('input', button.name)
      this.$emit('change', button.name)
    }
  }
}
</script>
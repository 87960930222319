import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import axios from '@/plugins/axios';
import { ProviderShopifySettings } from '@/types/interfaces';
const sortBy = require('lodash/sortBy'); 

@Module({namespaced: true})
export default class ShopifySettingsModule extends VuexModule {
  settings: object = {}

  @Action//({ commit: 'setItems' })
  async fetchLocations(): Promise<{items: any}> {
    try {
      const response = await axios.get(`/providers/shopify/settings/locations/list`)
      // let sorted = sortBy(response.data, (o:any) => { return o.code });
      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @MutationAction({ mutate: ['settings'] })
  async fetchSettings(): Promise<{settings: ProviderShopifySettings}> {
    try {
      const response = await axios.get(`/providers/shopify/settings`)
      return Promise.resolve({ settings: response.data })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @MutationAction({ mutate: ['settings'] })
  async updateSettings(settings: ProviderShopifySettings): Promise<{settings: ProviderShopifySettings}> {
    try {
      const response = await axios.put(`/providers/shopify/settings`, settings)
      return Promise.resolve({ settings: response.data })
    } catch (err) {
      return Promise.reject(err)
    }
  }
}
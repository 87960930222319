import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/style.css'
import i18n from './i18n'
require('./plugins/ui-components');
import axios from './plugins/axios';
require('./plugins/errorhandler');
require('./plugins/click-outside');
require('./plugins/errorhandler');
require('./plugins/filters');
import Utils from './plugins/utils'

import GlobalConfirm from './plugins/confirm/confirm';
Vue.use(GlobalConfirm)

import VTooltip from 'v-tooltip'
import '@/assets/css/tooltip.css'
Vue.use(VTooltip)

import Notifications from 'vue-notification';
Vue.use(Notifications)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// import UUID from "vue-uuid";
// Vue.use(UUID);

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import { localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json'
import cn from 'vee-validate/dist/locale/zh_CN.json'
import * as Rules from 'vee-validate/dist/rules'

for (var rule in Rules) {
  extend(rule, (Rules as any)[rule])
}

localize({ en, cn });

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(require('vue-moment'));

Vue.config.productionTip = false

Vue.prototype.$http = axios;

// function checkAuth() {
// 
//   console.log("2. checkAuth")
// 
//   store.dispatch('context/setAppLoading', true)
// 
//   return new Promise(async (resolve, reject) => {
//     if(true || Utils.inIframe()) {
//       console.log("2.1 inIframe")
//       // Here comes the code for Shopify
//     } else {
//       // We're getting the current token from localStorage
//       const token = window.localStorage.getItem(process.env.VUE_APP_COOKIE_NAME)
//       // console.log("token", process.env.VUE_APP_SHOPIFY_TOKEN)
//       if (token && (typeof token !== 'undefined') && token != 'undefined') {
// 
//         console.log("2.2 With token")
// 
//         try {
//           axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
//           await store.dispatch('users/fetchProfile')
// 
//           console.log("2.3 fetchProfile OK")
// 
//           try {
//             await store.dispatch('shopify/fetchAccount')
//             await store.dispatch('tmallProducts/fetchCounts')
// 
//             console.log("2.4 fetchAccount OK")
// 
//           } catch (err) {
//             console.log("2.5 ERROR")
//           }
// 
//           resolve(true)
// 
//         } catch (err) {
//           console.log("2.6 User profile - Error", err)
//           await store.dispatch('users/logout')
//           reject(err)
//         }
//       }
//     }
//     resolve(true)
//   })
// }

store.dispatch('context/setAppLoading', true)

function createApp() {
  return new Promise(async (resolve, reject) => {
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')

    resolve(true)
  })
}

// checkAuth().then(async () => {
  // createApp();
createApp().then(async () => {
  
  // if(true) { //Utils.inIframe() && window && window.location && window.location.search) {
    try {

      if(window && window.location && window.location.search) {
        let params: any = window.location.search
        .slice(1)
        .split('&')
        .map(p => p.split('='))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

        if(params.hmac) {
          await store.dispatch('shopify/setSignature', params)
          let isInstalled = await store.dispatch('shopify/login')
          if(!isInstalled) {
            try {
              let result: any = await store.dispatch('shopify/install', params.shop)
              if(result) {
                window.location = result.url
              }
            } catch (err) {

              switch (err.statusCode) {
                case 403: router.push('/errors/403'); break;
                default: router.push('/errors/unknown');
              }

              router.push('/errors/unknown')
              store.dispatch('context/setAppLoading', false)
            }
          } else {
            store.dispatch('context/setAppLoading', false)
          }
        } else if(!Utils.inIframe()) {
          let token = await store.dispatch('users/retrieveToken')
          if(token) {
            await store.dispatch('shopify/fetchAccount')
          }

          store.dispatch('context/setAppLoading', false)
        }
      } else {
        let token = await store.dispatch('users/retrieveToken')
        if(token) {
          await store.dispatch('shopify/fetchAccount')
        }

        store.dispatch('context/setAppLoading', false)
      }
      
    } catch (err) {
      store.dispatch('context/setAppLoading', false)
      switch (err.statusCode) {
        case 401: router.push({ name: 'users-auth' }); break;
        case 403: router.push('/errors/403'); break;
        default: router.push('/errors/unknown');
      }
    }
  // } else {
  //   console.log("4. No iframe")
  // }

// }).catch(err => {
//   createApp();
//   console.log("3.2 ERROR", err)
//   
//   switch(err.statusCode) {
//     case 401:
//       router.push('/errors/401'); 
//       break;
//     default: 
//       router.push('/errors/network');
//   }
// 
// }).finally(() => {
//   console.log("STOP LOADING!")
//   store.dispatch('context/setAppLoading', false)
// })



})
import { Module, VuexModule, MutationAction } from 'vuex-module-decorators'

@Module({namespaced: true})
export default class ContextModule extends VuexModule {
  appLoading:Boolean = false
  consumer:string = 'tmall'

  @MutationAction
  async setAppLoading(value: Boolean) {
    return { appLoading: value }
  }

  // @MutationAction
  // async setConsumer(value: string) {
  //   return { consumer: value }
  // }

  get getConsumer(): string {
    return this.consumer
  }

  get getConsumerName(): string {
    return 'Tmall'
  }
}
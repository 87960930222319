import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types';
import shopify from "./shopify/shopify";
import tmall from "./tmall/tmall";
import users from "./users";
import ShopifyProductsModule from './shopify/shopify-products';
import ShopifyOrdersModule from './shopify/shopify-orders';
import TmallProductsModule from './tmall/tmall-products';
import ContextModule from './context';
import ShopifySettings from './shopify/shopify-settings';
import TmallSettings from './tmall/tmall-settings';

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
      version: '1.0.0'
  },
  strict: false,
  modules: {
    shopify,
    tmall,
    users,
    shopifyProducts: ShopifyProductsModule,
    shopifyOrders: ShopifyOrdersModule,
    tmallProducts: TmallProductsModule,
    context: ContextModule,
    tmallSettings: TmallSettings,
    shopifySettings: ShopifySettings
  }
};

export default new Vuex.Store<RootState>(store);
<template>
  <div class="px-6 mb-2">
    <w-form class="flex gap-4"><slot name="fields"></slot></w-form>
    <div><slot name="selection"></slot></div>
  </div>
</template>
<script>
export default {
  name: 'w-filter'
}
</script>




















import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class Banner extends Vue {
  @Prop() private title: string
  @Prop(Boolean) private warning: Boolean;
  @Prop(Boolean) private success: Boolean;
  @Prop(Boolean) private info: Boolean;
  @Prop(Boolean) private withinContainer: Boolean;
}





























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: 'Dialog' })
export default class Button extends Vue {
  @Prop() private title: string
  @Prop(Boolean) private visible: Boolean
  @Prop(Boolean) private small: Boolean
  @Prop(Boolean) private medium: Boolean
  @Prop(Boolean) private large: Boolean
  @Prop({ type: Boolean, default: true }) private closable: Boolean

  mounted() {
    
  }

  show() {
    
  }

  close() {
    this.$emit('onClose')
  }
}

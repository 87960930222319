<template>
  <div class="flex mb-3 buttons-group">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'buttons-group'
}
</script>
<style scoped>
/* .buttons-group::v-deep .form-component:first-child > button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}
.buttons-group::v-deep .form-component:last-child > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
} */
.buttons-group::v-deep .form-component:not(:first-child) > button {
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.buttons-group::v-deep .form-component:not(:last-child) > button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
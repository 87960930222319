<template>
  <div :key="`_uid_${lastUpdate}`" class="relative">

    <div v-if="loading" slot="header" class="absolute inset-0 bg-white bg-opacity-70 px-6 z-10" :class="{'mt-14': showHeader}">
      <div class="rounded bg-pacific-200 h-11 flex px-6 items-center">
        <w-icon file="spinner" class="animate-spin" />
        <span class="ml-2">Loading...</span>
      </div>
    </div>

    <div class="hidden">
      <slot></slot>
    </div>
    <table 
      class="table table-auto w-full" 
      :class="{
        'border-b border-gray-200': !noBottomBorder,
        'no-header': !showHeader,
        'border border-gray-200 rounded': !noBorder
      }"
      style="border-collapse: inherit; border-spacing: inherit;"
    >
    <!-- border-collapse: inherit;
    border-spacing: inherit; -->
      <colgroup>
        <col v-if="checkbox" class="w-12"></col>
        <col 
          v-for="(column, index) in superColumns" 
          :width="column.width"
          :min-width="column.minWidth"
          :key="index"></col>
      </colgroup>

      <thead v-if="showHeader">
        <tr class="">
          <th v-if="checkbox" class="w-12">
            <ui element="checkbox" :value="isAllSelected" @input="clickAllCheckbox" />
          </th>
          <th 
            :class="{'first:pl-6 first:rounded-tl last:rounded-tr last:pr-6': !noXPadding, 'first:pl-0': noXPadding}"
            class="tpr-2 py-4"
            v-for="(column, index) in superColumns" 
            :key="index">
            <div class="flex items-center font-normal min-h-12"
              :class="[
                column.staticClass, 
                column.dynamicClass,
                {'justify-center': column.align == 'center', 'justify-end': column.align == 'right'}
              ]">
              <div
                v-if="column.label" 
                v-tooltip="column.help"
                @click="column.sortable && sort(column.field)"
                class="text-xxs leading-tight font-medium text-gray-500 flex items-center" 
                :class="[column.staticClass, column.dynamicClass, {'border-b cursor-pointer': column.help, 'cursor-pointer': column.sortable}]">

                <span class="flex flex-1 items-center mr-1" v-if="column.sortable && sortOptions">
                  <w-icon v-if="column.field != sortOptions.sortBy" size="2" file="sort" />
                  <w-icon v-else-if="column.field == sortOptions.sortBy && 'ASC' == sortOptions.sortDirection" size="2" file="sort-up" />
                  <w-icon v-else-if="column.field == sortOptions.sortBy && 'DESC' == sortOptions.sortDirection" size="2" file="sort-down" />
                </span>

                <span :class="{'text-gray-500': column.sortable && column.field == sortOptions.sortBy}">{{ column.label }}</span>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="bg-white rounded-b">
        <tr 
          v-for="(item, index) in data" 
          class="tr" 
          :key="`tr_${index}`"
          @click="clickItem(item, index)"
          :class="[item.rowClass, {'cursor-pointer hover:bg-gray-50': selectable, 'bg-indigo-100 hover:bg-indigo-200': checkbox && selectedItems.includes(item[selectBy])}]">

          <td v-if="checkbox" class="w-12 text-center">
            <div class="flex items-center justify-center">
              <ui element="checkbox" :value="selectedItems.includes(item[selectBy])" />
            </div>
          </td>

          <table-cell 
            :id="`${index}_${index_c}`"
            v-for="(column, index_c) in superColumns.filter(c => c.type != 'expand')" 
            :class="[
              column.staticClass, 
              column.dynamicClass,
              {
                'first:pl-6 pr-2 last:pr-6': !noXPadding,
                'first:pl-0 pr-2 last:pr-0': noXPadding,
                'align-top': valign == 'top',
                'text-center': column.align == 'center',
                'text-right': column.align == 'right'
              }
            ]"
            :column="column"
            :item="item"
            :rowIndex="index"
            :key="index_c">
          </table-cell>
        </tr>

        <tr v-if="$slots.total" class="bg-gray-50 font-semibold" slot="header">
          <slot name="total"></slot>
        </tr>
      </tbody>
    </table>
    <div v-if="data && _isEmpty(data)" class="flex py-3 justify-center text-gray-400">
      No Data
    </div>
  </div>
</template>
<script>
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import TableCell from '@/components/ui/table/TableCell'
const moment = require('moment')
export default {
  name: 'w-table',
  components: { TableCell },
  provide() {
    return {
      'wtable': this
    }
  },
  data() {
    return {
      superColumns: [],
      lastUpdate: moment().valueOf()
    }
  },
  props: {
    data: {
      required: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    noBottomBorder: Boolean,
    noBorder: Boolean,
    sortOptions: {},
    resetSort: Boolean,
    valign: {
      type: String,
      default: 'center'
    },
    noXPadding: Boolean,
    selectable: Boolean,
    checkbox: Boolean,
    selectBy: {
      type: String,
      default: 'id'
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    loading: Boolean
  },
  mounted() {
    try {
      this.buildColumns()
    } catch (error) {
      console.log("Error", error)
    }
  },
  watch: {
    data: {
      handler(value) {
        this.buildColumns()
      }
    }
  },
  computed: {
    isAllSelected() {
      return this.data.map(d => d[this.selectBy]).filter(item => this.selectedItems.includes(item)).length == this.data.length
    }
  },
  methods: {
    _isEmpty (obj) {
      return isEmpty(obj)
    },
    sort(field) {

      let getField = (value) => {
        if(this.resetSort) {
          if(this.sortOptions.sortBy == value && this.sortOptions.sortDirection == 'ASC') {
            return 'created'
          } 
        }
        return value
      }

      let getDirection = (value) => {
        if(this.sortOptions.sortBy == value) {
          return this.sortOptions.sortDirection == 'ASC' ? 'DESC' : 'ASC'
        } else {
          return 'DESC'
        }
      }

      field = getField(field)
      let direction = getDirection(field)

      this.$emit('on-change-sort', {prop: field, order: direction})
    },
    buildColumns() {
      const defaultProps = (options, data) => {
        let props = {}
        for (let key in options) {

          if (typeof options[key].default === 'boolean' && data[key] !== undefined) {
            props[key] = data[key] === false ? false : true
            continue
          }

          if (data[key] !== undefined) {
            props[key] = data[key]
            continue
          }

          if (typeof options[key].default === 'function') {
            props[key] = options[key].default()
            continue
          }

          props[key] = options[key].default
        }

        return props
      }


      let vnodes = !this.$slots.default ? [] : this.$slots.default.filter(vnode => vnode.componentOptions)

      this.superColumns = vnodes.map(vnode => {

        let { componentOptions: { Ctor: { options: { props } }, propsData, children }, data: { scopedSlots, attrs, class: dynamicClass, staticClass } } = vnode

        let { field, label, help, width, minWidth, sortable, render, type, align } = defaultProps(props, propsData)
        // let { render, label } = defaultProps(props, propsData)

        // let { width, minWidth, align, type, sortable, help, field } = vnode.data.attrs
        if (sortable !== undefined) {
          sortable = sortable === false ? false : true
        }

        return {
          field,
          label,
          help,
          width,
          minWidth,
          type,
          align,
          sortable,
          render,
          scopedSlots,
          children,
          attrs,
          dynamicClass,
          staticClass
        }
      })
    },
    clickItem(item, index) {
      let newArray = JSON.parse(JSON.stringify(this.selectedItems));

      if(newArray.includes(item[this.selectBy])) {
        this.$emit('onSelectionChange', newArray.filter(id => id != item[this.selectBy]))
      } else {
        newArray.push(item[this.selectBy])
        this.$emit('onSelectionChange', newArray)
      }

      this.$emit('onClickRow', item)
    },
    clickAllCheckbox(params) {
      let ok = 'test'      
      let newArray = JSON.parse(JSON.stringify(this.selectedItems));

      if(params) {
        let newSelected = this.data.map(d => d[this.selectBy])
        this.$emit('onSelectionChange', uniq(newArray.concat(newSelected)))
      } else {
        for(let item of this.data.map(d => d[this.selectBy])) {
          newArray = newArray.filter(a => a != item)
        }
        this.$emit('onSelectionChange', newArray)
      }
    }
  }
}
</script>
<style>
.table td {
  @apply border-t border-gray-200 py-4 leading-tight
}
.table.no-header tr:first-child td {
  @apply border-t-0
}
</style>












import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SectionComponent extends Vue {
  @Prop() private title: string;
  @Prop() private subtitle: string;
}

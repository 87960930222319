// import Vue from 'vue'
import EventBus from '@/plugins/confirm/event-bus'
import Confirm from '@/components/ui/Confirm.vue'

const GlobalConfirm = {
  install(Vue: any, args = {}) {

    Vue.component('w-confirm', Confirm)

    const confirm = (params: any) => {
    }
    confirm.show = function (options: any) {
      EventBus.$emit('show', options)
    }
    confirm.hide = function () {
      EventBus.$emit('hide')
    }

    Vue.prototype['$confirm'] = confirm
    Vue['confirm'] = confirm
  }
}

export default GlobalConfirm
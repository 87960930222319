let utils = {
  inIframe: () => {
    if(typeof window === 'undefined') {
      return null
    }
    else {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  },
  decamelize: (value: string) => {
    return value.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
  },
  capitalize: (value: string) => {
    if (!value) return ''
    value = value.toString().trim()
    value = value.replace(/[^\w\s]/gi, " ").replace(/_/g, " ");
    console.log("1.", value)
    console.log("2.", value.charAt(0).toUpperCase())
    console.log("3.", value.slice(1))
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
  lowerize: (value: string) => {
    if (!value) return ''
    return value.toString().toLowerCase()
  },
  getUrlParameter: (url: string, sParam: string) => {
    var 
      sPageURL = url.split('?')[1],
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
        return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  }
}

export default utils
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import axios from '@/plugins/axios';

import { config } from 'vuex-module-decorators'
config.rawError = true

@Module({namespaced: true})
export default class ShopifyOrdersModule extends VuexModule {
  orderInfo:any = null

  @Action
  async fetchOrderInfo(id: string) {
    try {
      const response = await axios.get(`/providers/shopify/synchronization/order/info?shopifyOrderId=${id}`)
      this.context.commit('setOrderInfo', response.data)
      return Promise.resolve(response.data)
    } catch (err) {
      this.context.commit('setOrderInfo', null)
      return Promise.reject(err)
    }
  }

  @Mutation
  setOrderInfo(orderInfo: any) {
    this.orderInfo = orderInfo
  }

  get getOrderInfo(): any {
    return this.orderInfo
  }
}

































import GlobalConfirm from '@/plugins/confirm/confirm';
import EventBus from '@/plugins/confirm/event-bus';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: 'ConfirmComponent' })
export default class ConfirmComponent extends Vue {
  private text: string = ''
  private title: string = ''
  private visible: Boolean = false
  private onAction?: Function
  private onConfirm?: Function
  private onCancel?: Function
  private actionButtonText: string = ''
  private buttonText: string = ''
  private type: string = ''
  private canOnlyConfirm: Boolean = false
  private loading: Boolean = false

  beforeDestroy(){
    EventBus.$off("show", this.showConfirm)
    EventBus.$off("hide", this.hideConfirm)
  }

  mounted () {
    EventBus.$on('show', this.showConfirm);
    EventBus.$on('hide', this.hideConfirm);
  }

  hideConfirm() {
    this.loading = false
    this.visible = false;
  }
  close() {
    this.loading = false
    if(!this.canOnlyConfirm) {
      this.hideConfirm()
    }
  }
  confirm() {
    this.loading = true
    if(typeof this.onConfirm === 'function') {
      this.onConfirm();
    } else {
      this.hideConfirm()
    }
  }
  cancel() {
    this.loading = false
    if(typeof this.onCancel === 'function') {
      this.onCancel()
      this.visible = false;
    } else {
      this.visible = false;
    }
  }
  doAction () {
    if(typeof this.onAction === 'function') {
      this.onAction();
    } else {
      this.hideConfirm()
    }
  }
  showConfirm(params: any) {
    this.visible = true;
    this.title = params.title;
    this.text = params.text;
    
    this.actionButtonText = params.actionButtonText
    this.buttonText = params.buttonText
    this.type = params.type || this.type;

    this.canOnlyConfirm = params.canOnlyConfirm;
    
    this.onAction = params.onAction;
    this.onConfirm = params.onConfirm;
    this.onCancel = params.onCancel;
  }
}

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import TmallProduct from '@/models/TmallProduct';
import axios from '@/plugins/axios';
import { IPagination } from '@/types/interfaces';

enum ProviderType {
  shopify = 'shopify'
}

import { config } from 'vuex-module-decorators'
config.rawError = true

@Module({namespaced: true})
export default class TmallProductsModule extends VuexModule {
  count:number = 0
  items:TmallProduct[] = []
  productsToMatch:number = 0
  matchedProducts:number = 0
  totalProducts:number = 0

  @Action({ commit: 'setItems' })
  async fetchAll({ pagination: { page, pageSize }, isMatched, isCainiao}: { pagination: IPagination, isMatched: Boolean, isCainiao: Boolean}): Promise<{items: TmallProduct[], count: number}> {

    let skip = (page - 1) * pageSize
    try {
      const response = await axios.get(`/consumers/tmall/products/list?skip=${skip}&limit=${pageSize}&sort=createdAt&isMatched=${isMatched}&isCainiao=${isCainiao}`)
      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    } 
  }

  @Action({ commit: 'setItems' })
  async searchAll({pagination, q, isMatched, isCainiao}: { pagination: IPagination, q: string, isMatched: Boolean, isCainiao: Boolean}): Promise<{items: TmallProduct[], count: number}> {
    let skip = (pagination.page - 1) * pagination.pageSize
    try {
      const response = await axios.get(`/consumers/tmall/products/search?skip=${skip}&limit=${pagination.pageSize}&sort=createdAt&isMatched=${isMatched}&isCainiao=${isCainiao}&q=${q}`)
      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Action({ commit: 'setMatch' })
  async match({ consumerComponentId, providerComponentId, providerType }: { consumerComponentId: string, providerComponentId: string, providerType: ProviderType }): Promise<TmallProduct> {
    try {
      const response = await axios.post(`/consumers/tmall/products/${consumerComponentId}/match`, {
        providerComponentId: providerComponentId,
        providerType: providerType
      })
      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Action({ commit: 'setUnmatch' })
  async unmatch({ consumerComponentId, providerType }: { consumerComponentId: string, providerType: ProviderType }): Promise<TmallProduct> {
    try {
      const response = await axios.delete(`/consumers/tmall/products/${consumerComponentId}/match`, {
        params: { providerType: providerType }
      })
      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Action({ commit: 'setCount' })
  async fetchCount(isMatched: Boolean) {
    try {
      const response = await axios.get(`/consumers/tmall/products/count?isMatched=${isMatched}`)
      return Promise.resolve({ isMatched: isMatched, count: response.data.count })
    } catch (err) {
      return Promise.reject(err)
    }
  }
  @Action({ commit: 'setCounts' })
  async fetchCounts() {
    try {
      const total = await axios.get(`/consumers/tmall/products/count`)
      const matched = await axios.get(`/consumers/tmall/products/count?isMatched=true`)
      return Promise.resolve({ matched: matched.data.count, unmatched: (total.data.count - matched.data.count), total: total.data.count })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Action
  async synchronizeProducts() {
    try {
      const response = await axios.post(`/consumers/tmall/synchronization/products`)
      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Mutation
  setItems(payload: {items: TmallProduct[], count: number}) {
    this.items = payload.items
    this.count = payload.count
  }

  @Mutation
  setCount({isMatched, count}: {isMatched: Boolean, count: number}) {
    if(isMatched === true) {
      this.matchedProducts = count
    } else if (isMatched === false) {
      this.productsToMatch = count
    } else {
      this.totalProducts = count
    }
  }

  @Mutation
  setCounts({matched, unmatched, total}: {matched: number, unmatched: number, total: number}) {
    this.matchedProducts = matched
    this.productsToMatch = unmatched
    this.totalProducts = total
  }

  @Mutation
  setMatch(payload: TmallProduct) {
    Object.assign(this.items[this.items.findIndex(el => el.id === payload.id)], payload)
    this.productsToMatch -= 1
    this.matchedProducts += 1
  }

  @Mutation
  setUnmatch(payload: TmallProduct) {
    Object.assign(this.items[this.items.findIndex(el => el.id === payload.id)], payload)
    this.productsToMatch += 1
    this.matchedProducts -= 1
  }

  get getProducts(): {items: TmallProduct[], count: number} {
    return {
      items: this.items, count: this.count
    }
  }

  get getCount(): number {
    return this.productsToMatch
  }
}
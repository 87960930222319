



































import { Component, Prop, Vue } from "vue-property-decorator";
import FormElement from '@/components/ui/FormElement.vue';

@Component
export default class Input extends FormElement {
  @Prop({default: 'text'}) public type?: string;
  @Prop() public value?: string;
  @Prop() public name?: string;
  @Prop() public placeholder?: string;
  @Prop(Boolean) public disabled: Boolean;
  @Prop(Boolean) public readOnly: Boolean;
  @Prop(Boolean) public clearable: Boolean;
  @Prop(Boolean) public loading: Boolean;
  @Prop(Boolean) public autocomplete: Boolean;

  clear() {
    this.$emit('onClear')
  }

  get getName() {
    if(this.name) {
      return this.name
    } else if (this.placeholder) {
      return this.placeholder
    }
  }
}

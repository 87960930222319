<template>
  <div class="form-component" v-click-outside="close">
    <w-button :id="`button_${this._uid}`" @click="toggle" :active="isOpen">
      {{ (value && !keepLabel) ? options.find(o => o.value == value).label : label }}
      <template #appendIcon>
        <w-icon file="chevron-down" />
      </template>
    </w-button>

    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div :id="`dropdown_${this._uid}`" :class="{'hidden': !isOpen}" class="absolute z-10">
        <div class="bg-white rounded-md border py-2 shadow-sm mt-2">
          <template v-if="options && options.length > 0">
            <div class="flex items-center cursor-pointer font-light px-6 py-2 hover:bg-gray-50" v-for="option in options" @click="select(option.value)" :key="option.value">
              <div 
                class="w-4 h-4 border-2 border-white mr-4 ring-2 ring-gray-300"
                :class="{
                  'bg-pacific-400 ring-pacific-400': isSelected(option.value),
                  'rounded-full': !multiple,
                  'rounded-sm': multiple
                }"></div>
              <div class="flex-1">{{ option.label }}</div>
            </div>
            <div class="px-6 border-t pt-2 mt-2">
              <w-button plain @click="clear()">Clear</w-button>
            </div>
          </template>
          <template v-else>
            <div class="text-gray-400 px-6 py-2">No option to select</div>
          </template>
        </div>
      </div>
    </transition>

  </div>
</template>
<script>
import { createPopper } from '@popperjs/core';
export default {
  name: 'choice-select',
  props: {
    value: {
      type: [String, Array],
      default: () => { this.multiple ? [] : null }
    },
    label: {
      type: String,
      default: 'Select'
    },
    options: {
      type: Array,
      default: () => []
    },
    keepLabel: Boolean,
    multiple: Boolean
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggle() {
      if(this.isOpen) {
        this.isOpen = false
      } else {
        this.isOpen = true
        this.setupPopper()
      }
    },
    close() {
      this.isOpen = false
    },
    setupPopper() {
      if(this.popper === undefined) {
        const button = document.querySelector(`#button_${this._uid}`);
        const dropdown = document.querySelector(`#dropdown_${this._uid}`);
        this.popper = createPopper(button, dropdown, {
          placement: 'bottom-start',
        });
        this.popper.update()
      } else {
        this.popper.update()
      }
    },
    select(value) {
      if(this.multiple) {
        if(this.isSelected(value)) {
          this.$emit('input', this.value.filter(v => v != value))
        } else {
          this.$emit('input', this.value.concat(value))
        }
      } else {
        this.$emit('input', value)
        this.close()
      }
    },
    isSelected(value) {
      if(this.multiple) {
        return (this.value ? this.value.includes(value) : false)
      } else {
        return (value == this.value)
      }
    },
    clear() {
      if(this.multiple) {
        this.$emit('input', [])
      } else {
        this.$emit('input', null)
      }
    }
  }
}
</script>
<template>

</template>
<script>
export default {
  name: 'w-table-column',
  props: {
    label: {
      type: String
    },
    help: { type: String },
    field: {
      type: String,
      default: ''
    },
    sortable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default'
    },
    width: {
      
    },
    minWidth: {},
    align: {
      type: String,
      default: 'left'
    }
  },
  inject: {
    wtable: {
      default: null
    }
  },
  created() {
    this.wtable.buildColumns()
  },
  render (h) {
    return h()
  },
  beforeDestroy() {
    this.wtable.buildColumns()
  }
}
</script>
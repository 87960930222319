















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  @Prop() public title?: string;
  @Prop() public subtitle?: string;
  @Prop(Boolean) public noBorder: Boolean;
}

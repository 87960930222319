<template>
  <div>
    <div v-show="active">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    name: [String, Number],
    to: {},
    exact: {
      type: Boolean,
      devault: true
    },
    icon: String,
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    }
  },
  inject: {
    buttons: {
      default: []
    },
    menuState: {
      default: null
    }
  },
  watch: {
    title: {
      handler(newVal) {
        this.buttons.find(b => b.name == this.name).title = newVal
      }
    }
  },
  created() {
    this.buttons.push({ title: this.title, name: this.name, to: this.to, exact: this.exact, icon: this.icon, disabled: this.disabled, tooltip: this.tooltip })
  },
  computed: {
    active() {
      if(this.menuState) {
        return this.name == this.menuState.activeItem
      }
    }
  }
}
</script>
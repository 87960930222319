import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import axios from '@/plugins/axios';
import { ConsumerTmallSettings, ITmallCredentials } from '@/types/interfaces';
const sortBy = require('lodash/sortBy');
const cloneDeep = require('lodash/cloneDeep');

@Module({namespaced: true})
export default class TmallSettingsModule extends VuexModule {
  settings: object = {};

  @Action//({ commit: 'setItems' })
  async fetchLogisticCompanies(): Promise<{items: any}> {
    try {
      const response = await axios.get(`/consumers/tmall/settings/logistics/list`)
      let sorted = sortBy(response.data, (o:any) => { return o.code });

      return Promise.resolve(sorted)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @MutationAction({ mutate: ['settings'] })
  async fetchSettings(): Promise<any> {
    try {
      const response = await axios.get(`/consumers/tmall/settings`)
      return Promise.resolve({ settings: response.data })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @MutationAction({ mutate: ['settings'] })
  async updateSettings(settings: ConsumerTmallSettings): Promise<{ settings: ConsumerTmallSettings }> {
    try {
      const response = await axios.put(`/consumers/tmall/settings`, settings)
      return Promise.resolve({ settings: response.data })
    } catch (err) {
      return Promise.reject(err)
    }
  }
}
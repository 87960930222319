<template>
  <ValidationProvider :ref="`provider_${_uid}`" :id="`provider_${_uid}`" :key="_uid" :rules="rules" :debounce="debounce" :name="name || label" v-slot="params" :mode="mode" :slim="true">
    <div :class="{'mb-4': !slim}">
      <label v-if="($slots.label || label) && !hideLabel" class="block mb-2 font-medium text-gray-700"><slot name="label">{{ label }}</slot><span v-if="isRequired" class="text-red-500">*</span></label>

      <!-- {{ params.valid }} {{ params.untouched }} {{ params.touched }} -->
      <div :class="{'error': params.valid === false && params.validated === true}">
        <slot :validate="params.validate"></slot>
      </div>
      <div v-if="$slots.help" class="mt-1 text-sm text-gray-400"><slot name="help"></slot></div>
      <div
        class="mt-1 text-sm text-red-400" 
        v-if="!hideErrorMsg">
        {{ params.errors[0] }}
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from 'vee-validate';

export default {
  // name: 'formField',
  name: 'ElFormItem',
  componentName: 'ElFormItem',
  components: { ValidationProvider },
  inject: {
    model: {
      default: null
    }
  },
  props: {
    label: {
      type: String
    },
    name: {
      type: String
    },
    slim: Boolean,
    hideErrorMsg: Boolean,
    hideLabel: Boolean,
    rules: {
      type: String
    },
    mode: {
      type: String,
      default: 'aggressive'
    },
    debounce: {
      type: Number,
      default: 0
    },
    required: Boolean,
    prop: {
      type: String,
      default: null
    }
  },
  provide: function() {
    return {
      'model': this.model,
      'getProp': this.getProp
    }
  },
  methods: {
    getProp: function() {
      return this.prop;
    }
  },
  mounted() {
  },
  computed: {
    isRequired() {
      if(this.required) return this.required
      if(this.rules) {
        return this.rules.split('|').length > 0 ? this.rules.split('|').includes('required') : false
      }
    }
  }
}
</script>